import { Controller } from '@hotwired/stimulus'

// Displays the time for a date on the current day, otherwise falls back to day month yeaer
export default class extends Controller {
  static values = { interval: { type: Number, default: Math.trunc(1000 * 60 * 4.5) } }

  connect () {
    this.startRefresh()
  }

  disconnect () {
    clearInterval(this.intervalId)
  }

  startRefresh () {
    this.intervalId = setInterval(() => {
      this.element.reload()
    }, this.intervalValue)
  }
}
