import { Controller } from 'stimulus'

import { createConsumer } from '@rails/actioncable'

export default class extends Controller {
  static targets = [
    'restUrl',
    'cableUrl',
    'cableStatus',
    'cableReceived',
    'channel',
    'restOutput',
    'cableOutput']

  static values = {
    restUrl: String,
    cableUrl: String,
    channel: Object,
    cableReceived: {
      type: Number,
      default: 0
    }
  }

  connect () {
    this.restUrlTarget.textContent = this.restUrlValue
    this.cableUrlTarget.textContent = this.cableUrlValue
    this.channelTarget.textContent = JSON.stringify(this.channelValue)

    setTimeout(() => {
      this.fetchRest()
    }, 0)

    setTimeout(() => {
      this.connectCable()
    }, 0)
  }

  async fetchRest () {
    const response = await fetch(this.restUrlValue)
    const data = await response.json()

    this.restOutputTarget.textContent = JSON.stringify(data)
  }

  async connectCable () {
    // Create the consumer
    this.cableStatusTarget.textContent = 'Connecting...'
    const consumer = createConsumer(this.cableUrlValue)

    // Connect to the channel
    consumer.subscriptions.create(this.channelValue, {
      received: (data) => {
        this.cableReceivedValue++
        this.cableReceivedTarget.textContent = this.cableReceivedValue
        this.cableOutputTarget.textContent = JSON.stringify(data)
      },

      // Called when the subscription is ready for use on the server.
      connected: () => {
        this.cableStatusTarget.textContent = 'Connected'
      },

      // Called when the subscription is disconnected by the server.
      disconnected: () => {
        this.cableStatusTarget.textContent = 'Disconnected'
      },

      // Called when the subscription is rejected by the server.
      rejected: () => {
        this.cableStatusTarget.textContent = 'Rejected'
      }
    })
  }
}
