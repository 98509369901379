import { Controller } from '@hotwired/stimulus'

import { withStatusPolling } from '../../src/mixins/with_status_polling'

/**
 * Retrieves the Dock Status from the Drone Status Service
 * and broadcasts it to the page (via a statusUpdate event)
 */
export default class extends Controller {
  static values = {
    refreshRate: {
      type: Number,
      default: 1_000
    },
    url: String
  }

  connect () {
    withStatusPolling(this, this.urlValue, this.refreshRateValue, this.dispatchStatusUpdate.bind(this))
  }

  // droneStatus object schema example:

  // {
  //   "drone": {
  //     "_links": {
  //       "self": {
  //         "href": "/api/v1/drones/ABC1234"
  //       }
  //     },
  //     "serial_number": "ABC1234",
  //     "height": 123.456789,
  //     "elevation": 12.3,
  //     "position": {
  //       "latitude": 12.345678,
  //       "longitude": 123.456789
  //     },
  //     "_meta": {
  //       "last_timestamp": "2019-08-24T14:15:22Z",
  //       "live_status": true
  //     }
  //   }
  // }

  dispatchStatusUpdate (res) {
    // Testing locally:
    // const droneStatus = {
    //   position: {
    //     latitude: -33.9129314 + (new Date().getSeconds()) / 500,
    //     longitude: 151.1942447 + Math.random() / 500
    //   },
    //   elevation: 100 + (new Date().getSeconds()) * 100,
    //   "_meta": {
    //       "last_timestamp": "2019-08-24T14:15:22Z",
    //       "live_status": true
    //     }
    // }

    const droneStatus = res.drone || {} // unknown drones return null, do not allow null to propagate

    const droneMeta = droneStatus._meta || { live_status: false } // unknown drones should not be live

    // Broadcast a liveUpdate event to the page
    const liveDroneStatus = droneMeta.live_status ? droneStatus : {}

    this.dispatch('liveUpdate', { detail: { drone_status: liveDroneStatus, meta: droneMeta } })
  }
}
