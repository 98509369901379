import MarkerHub from '../../../images/marker-hub.svg'

export const withHubMarker = (_controller, { cesiumViewer }) => {
  let entity = null
  let hubName = null
  let hubLatitude = null
  let hubLongitude = null

  const updateEntity = () => {
    if (hubLatitude && hubLongitude) {
      const entityPosition = Cesium.Cartesian3.fromDegrees(hubLongitude, hubLatitude)

      if (entity) {
        // Update the existing entity
        entity.name = hubName
        entity.position = entityPosition
      } else {
        // Create the entity and add it to the viewer
        entity = cesiumViewer.entities.add({
          position: entityPosition,
          id: 'Hub',
          name: hubName,
          billboard: {
            image: MarkerHub,
            pixelOffset: new Cesium.Cartesian2(0, -21),
            pixelOffsetScaleByDistance: new Cesium.NearFarScalar(
              150.0, 1.5,
              1.5e7, 0.5
            ),
            scaleByDistance: new Cesium.NearFarScalar(1.5e2, 0.2, 1.5e7, 0.1)
          }
        })
      }
    }
  }

  return {
    update: ({ name, latitude, longitude }) => {
      hubName = name
      hubLatitude = latitude
      hubLongitude = longitude
      updateEntity()
    },
    getEntity: () => entity
  }
}
